/**
 * @Description Redux store for the application
 * @FileName appStore.js
 * @Author Arnab Sutar - sutaarn
 * @CreatedOn 22 January, 2021 08:48:11
 * @IssueID - 308
*/
import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';

import AppRootReducer from './appRootReducer';
import AppRootSaga from './appRootSaga';
import { environment } from '../app/common/util';

// create the sagaa middleware
const sagaMiddleware = createSagaMiddleware();
const envData = environment();

export const middleware = [sagaMiddleware];
// configure store with saga middleware and root reducer
export const AppStoreData = (envDataVal) => (envDataVal.enableDevTool ? createStore(
  AppRootReducer,
  composeWithDevTools(applyMiddleware(...middleware)),
) : createStore(
  AppRootReducer,
  applyMiddleware(...middleware),
));

const appStrore = AppStoreData(envData);
/**
 * Un-comment the below section and comment above appStore creation statement
 * in case user doesn't want to have redux dev tool
 */

sagaMiddleware.run(AppRootSaga);

export default appStrore;
