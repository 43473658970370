/**
 * @Description File generated by awesome-react-template
 * @FileName index.js
 * @Author Arnab Sutar - sutaarn
 * @CreatedOn 08 January, 2021 14:21:27
 * @IssueID - 459, 305, 308
 */
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './index.scss';
import App from './app';
import appStore from './stateManagement/appStore';

ReactDOM.render(
  <Suspense fallback="loading">
    <React.StrictMode>
      <Provider store={appStore}>
        <App />
      </Provider>
    </React.StrictMode>
  </Suspense>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(// console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
