import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { getRBACData, getBatchExportData, getUserQuicksLinks, getAllQuicksLinks,
  saveUserSelQuickLinks, getIARdata, getLevelRBACData, commonTypes,
  resetStore } from './app/common/redux/actions/commonActions';
import { globalConstants, staticCommonLabelKeys } from './app/common/moduleConstants';
import { FasatExpandablePanel, FasatPage, FasatQuickLinks, FasatRouterLink } from './app/common/components';
import { getCurrentLocale } from './app/common/i18n';
import getMenuItems from './config/menuConfig';
import { getDateFormat, initialReqObject, IsACLEnabled, isHasUserClaim,
  // negetiveValueFormat,
  currencyFormat } from './app/common/util/utility';
import logger from './app/common/util/appLogger';
import { staticLabelKeys } from './app/modules/admin/moduleConstants';
import FasatBatchUIConcurrency from './app/common/components/fasatBatchUIConcurrency/fasatBatchUIConcurrency';
import BatchExport from './app/common/libs/fasatSharedComponent/batchExport';
import FasatPersonilizedSec from './fasatPersonilizedSec';
import FasatCompStatement from './app/modules/compcal/containers/fasatCompStatement/fasatCompStatement';
import UserContext from './userContext';
import { aclKeysConstant } from './app/modules/report/moduleConstants';
import { getAction } from './stateManagement/reduxUtil';
import FasatHomeMessageAlert from './fasatHomeMessageAlert';

const { dateFormatMMDDYYYY, external, internal } = globalConstants;

const chkRBACLengthEqlZero = (loggedUser, rbacData) => (loggedUser && rbacData && rbacData.access.length === 0);
const chkRBACLenGrtZero = (loggedUser, rbacData) => (loggedUser && rbacData && rbacData.access.length > 0);
const interActiveReportLink = '/reports/interactiveAcitvityReports';

export const addLinks = (userType, menu, addToQLinkMenu) => {
  if (userType === external) {
    addToQLinkMenu(menu);
  }
  if (userType === internal) {
    addToQLinkMenu(menu);
  }
  return addToQLinkMenu;
};
export const setQuickLinks = (quickLinks) => quickLinks.sort((a, b) => {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
});
export const setLinks = async ({ rbacMenus, arr, addToQLinkMenu, setQuickLink, qLinkMenu, loggedUser }) => {
  const userType = loggedUser?.UserType ? loggedUser.UserType : 'internal';
  return (
    rbacMenus.forEach((menu) => {
      (menu.subMenu).forEach((sMenu) => {
        if ((sMenu.subMenu) !== undefined) {
          (sMenu.subMenu).forEach((ssMenu) => {
            if (arr.indexOf(ssMenu.id) > -1) {
              addLinks(userType, ssMenu, addToQLinkMenu);
            }
          });
        } else if (arr.indexOf(sMenu.id) > -1) {
          addLinks(userType, sMenu, addToQLinkMenu);
        } else {
          logger.verbose('fix sonar issue');
        }
      });
      setQuickLink(setQuickLinks(qLinkMenu));
    })
  );
};
export const unauthorizedUser = ({ loggedUser, rbacData, userPrefError, t }) => (
  <>
    {loggedUser && !rbacData && (
    <div className="notAuthorizedSection">{t(staticCommonLabelKeys.USER_NOT_FOUND)}</div>
    )}
    {loggedUser && rbacData && userPrefError && (
    <FasatPage
      pageHeaderBtnSection={false}
      pageName="errorGen"
    />
    )}
  </>
);

export const batchExportFunc = (dispatch, batchExportData, reqObj) => {
  if (batchExportData === undefined || (batchExportData && batchExportData.data.length === 0)) {
    dispatch(getBatchExportData(reqObj));
  }
};

export const redirectlogin = (notLogin) => {
  if (notLogin === true) {
    window.location = 'bff/login';
  }
};

export const renderMenuArr = ({ userPrefData, isBatchProcessing }) => {
  const arr = [];
  if (userPrefData && userPrefData.length > 0) {
    userPrefData.forEach((item) => {
      if (isBatchProcessing === 1 && (item.batch_Online_Concurrency_Flag === 1)) {
        arr.push(item.menuId);
      }
      if (!isBatchProcessing) {
        arr.push(item.menuId);
      }
    });
  }
  return arr;
};
const displayPersonalizeModal = ({ dispPerModal, setDispPerModal, menuItems }) => (
  <FasatPersonilizedSec dispPerModal={dispPerModal} setDispPerModal={setDispPerModal} menuItems={menuItems} />
);

const decimalPlace = '2';

const iarDashboardSection = ({ t, interactiveReportData, iarAmount, iARPeriodAmount, currentLocale }) => {
  const zeroAmt = interactiveReportData?.map((data) => data.amount.toFixed(decimalPlace));
  return (
    <div className="currPerEarnSection">
      <FasatExpandablePanel
        title={t(staticCommonLabelKeys.COMMON_LABEL_IAR_TITLE)}
        variant="primary"
        expandedByDefault
      >
        <div className="earnPeriodDiv">
          {t(staticCommonLabelKeys.COMMON_LABEL_SCE_FOR_PERIOD)}
          {
                   interactiveReportData?.map(
                     (data) => (
                       <>
                         { ' '}
                         {getDateFormat(data.fromDate, dateFormatMMDDYYYY)}
                         {' '}
                         {t(staticCommonLabelKeys.COMMON_LABEL_TO)}
                         {' '}
                         {getDateFormat(data.toDate, dateFormatMMDDYYYY)}
                       </>
                     ),
                   )
                  }
        </div>
        {t(staticCommonLabelKeys.COMMON_LABEL_IAR_TAB_CONTENT)}
        <div className="earningDetailsSec">
          <div className="earningDetails">
            {iarAmount === 0 && (currentLocale === 'en' ? `$ ${zeroAmt}` : `${zeroAmt} $`)}
            {iarAmount !== 0
            && (
            <FasatRouterLink to={{ pathname: interActiveReportLink }}>
              {iARPeriodAmount}
            </FasatRouterLink>
            )}
          </div>
        </div>
      </FasatExpandablePanel>
    </div>
  );
};

const rBACLengthEqlZero = ({ welcomeLabel, loggedUser, t, quickLink }) => (
  <FasatPage
    title={welcomeLabel}
    titleInItalics={((loggedUser.UserName).split(' '))[0]}
    pageHeaderBtnSection={false}
    pageName="landing"
  >
    <div>
      {loggedUser.UserType === 'external' && <FasatHomeMessageAlert />}
      <FasatQuickLinks
        quickLinkLabel={t(staticLabelKeys.LS_LABEL_QUICKLINKS)}
        noLinkMsg={t(staticLabelKeys.LS_NOQUICKLINK_MSG)}
        quickLinks={quickLink}
      />
    </div>
  </FasatPage>
);

const dependencyConditionOne = ({ loggedUser, dispatch, batchExportData, reqObj, sysOptData }) => {
  if (loggedUser) {
    dispatch(getRBACData());
    dispatch(getLevelRBACData());
    dispatch(getAllQuicksLinks(loggedUser));
    dispatch(getUserQuicksLinks());
    batchExportFunc(dispatch, batchExportData, reqObj);
  }
  if (sysOptData && loggedUser) {
    const iarPayload = { ...reqObj };
    iarPayload.parameters.push(
      { key: 'loggedInUser', operator: 'OPT_EQUALS', type: 'text', values: [loggedUser.UserId] },
      { key: 'sod_syt_dt', operator: 'OPT_EQUALS', type: 'text', values: [sysOptData[0].value] },
    );
    if (loggedUser.UserType === external) {
      dispatch(getIARdata(iarPayload));
    }
  }
};

export const userTypeCondtion = (userType, rbacData, aclkey) => ((userType && userType === external)
 && isHasUserClaim(rbacData, aclkey));

export const alertMsgForgrtZero = (loggedUser) => loggedUser.UserType === 'external' && <FasatHomeMessageAlert />;
const FasatHome = ({ menuItems }) => {
  const [t] = useTranslation();
  const [quickLink, setQuickLink] = useState([]);
  const [dispPerModal, setDispPerModal] = useState(false);
  const welcomeLabel = t(staticCommonLabelKeys.RBAC_LABEL_WELCOME);
  const interactiveReportData = useSelector(({ ApplicationState }) => ApplicationState.iraData);
  const loggedUser = useSelector(({ ApplicationStateRBAC }) => ApplicationStateRBAC.loggedUser);
  const rbacData = useSelector(({ ApplicationStateRBAC }) => ApplicationStateRBAC.rbacData);
  const userPrefData = useSelector(({ ApplicationStateRBAC }) => ApplicationStateRBAC.userPrefData);
  const userPrefError = useSelector(({ ApplicationStateRBAC }) => ApplicationStateRBAC.userPrefError);
  const batchExportData = useSelector(({ ApplicationStateRBAC }) => ApplicationStateRBAC.batchExportData);
  const notLogin = useSelector(({ ApplicationStateRBAC }) => ApplicationStateRBAC.notLogin);
  const isBatchProcessing = useSelector(({ ApplicationState }) => ApplicationState.isBatchProcessing);
  const sysOptData = useSelector((state) => state.ApplicationState.sysOptData);
  const dispatch = useDispatch();
  const locale = getCurrentLocale();
  const reqObj = initialReqObject({ locale });
  const qLinkMenu = [];
  const history = useHistory();
  const userType = useContext(UserContext);
  const [iARPeriodAmount, setIARAmount] = useState([]);
  const iarAmount = interactiveReportData[0]?.amount;
  const currentLocale = getCurrentLocale();

  if (window.location.pathname !== '/') {
    history.push('/');
  }
  const addToQLinkMenu = (menu) => qLinkMenu.push({ name: menu.label, href: menu.urlPath, id: menu.id });
  useEffect(() => {
    if (loggedUser?.UserName) {
      dispatch(getRBACData());
      dispatch(getLevelRBACData());
    }
  }, [loggedUser]);
  useEffect(() => {
    if (interactiveReportData?.length > 0) {
      setIARAmount(currencyFormat(interactiveReportData[0].amount, currentLocale, decimalPlace, 'withSymbol'));
    }
  }, [interactiveReportData]);
  useEffect(() => redirectlogin(notLogin), [notLogin]);
  useEffect(() => {
    const arr = renderMenuArr({ userPrefData, isBatchProcessing });
    const rbacMenus = getMenuItems().filter((item) => IsACLEnabled(rbacData, item, loggedUser));
    setLinks({ rbacMenus, arr, addToQLinkMenu, setQuickLink, qLinkMenu, userType });
  }, [userPrefData, rbacData]);
  useEffect(() => {
    dependencyConditionOne({ loggedUser, dispatch, batchExportData, reqObj, sysOptData });
  }, [loggedUser, locale, sysOptData]);
  useEffect(() => {
    batchExportFunc(dispatch, batchExportData, reqObj);
    dispatch(resetStore());
    dispatch(getAction(commonTypes.SYSOPT_DATA_REQ, ['SOD_SYT_DT']));
  }, []);
  const resetLinks = () => dispatch(saveUserSelQuickLinks({ entityId: [] }));

  if (isBatchProcessing === 1 && quickLink.length === 0) {
    return (<FasatBatchUIConcurrency />);
  }
  const displayPersonlaize = () => setDispPerModal(true);
  return (
    <div className="landingWithBE">
      {chkRBACLenGrtZero(loggedUser, rbacData) && (
        <FasatPage
          title={welcomeLabel}
          titleInItalics={((loggedUser.UserName).split(' '))[0]}
          pageHeaderBtnSection={false}
          pageName="landing"
          extScreen
        >
            {alertMsgForgrtZero(loggedUser)}
          <div>
            <FasatQuickLinks
              quickLinkLabel={t(staticLabelKeys.LS_LABEL_QUICKLINKS)}
              quickLinks={quickLink}
              personalizeClick={displayPersonlaize}
              setDispPerModal={setDispPerModal}
              resetLinks={resetLinks}
            />
            {userTypeCondtion(userType, rbacData, aclKeysConstant.RBAC_IAR_MENU) && (
              <div>
                {iarDashboardSection({ t, interactiveReportData, iarAmount, iARPeriodAmount, currentLocale })}
              </div>
            )}
            {userTypeCondtion(userType, rbacData, aclKeysConstant.RBAC_ECS_MENU) && (
            <div className="compStmtDashboard"><FasatCompStatement /></div>
            )}
            {(batchExportData && batchExportData.data.length > 0) && <BatchExport />}
          </div>
          {displayPersonalizeModal({ t, dispPerModal, setDispPerModal, menuItems })}
        </FasatPage>
      )}
      {chkRBACLengthEqlZero(loggedUser, rbacData) && (
        rBACLengthEqlZero({ t, welcomeLabel, loggedUser, quickLink })
      )}
      {unauthorizedUser({ loggedUser, rbacData, userPrefError, t })}
    </div>
  );
};

FasatHome.propTypes = {
  menuItems: PropTypes.arrayOf(PropTypes.shape({})),
};

FasatHome.defaultProps = {
  menuItems: null,
};
export default FasatHome;
