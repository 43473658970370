/* eslint-disable no-unused-vars */
/**
 * @Description Root saga for the application
 * @FileName appRootSaga.js
 * @Author Arnab Sutar - sutaarn
 * @CreatedOn 22 January, 2021 08:47:27
 * @IssueID - 308
 */
import { all } from 'redux-saga/effects';
import ApplicationBaseWatcher from '../app/common/redux/sagas/applicationBaseWatcher';
import { adminWatcher, adminGenEditWatcher, sysParamWatcher, chequeSecurityWatcher,
  transacDefWatcher, incomeExpWatcher, dailyProcessWatcher, configureLettersWatcher,
  interfaceMapWatcher, interfaceSetupWatcher, producerAuditTrailWatcher, notificationsWatcher,
  DataTransferWatcher, fieldForceStructureModuleWatcher, accessOptWatcher, manageSecurityGroupWatcher,
  manageUserWatcher, manageLevelWatcher, fieldforceSecurityWatcher,
} from '../app/modules/admin';
import { agencyWatcher, clientWatcher, tempAdjustmentWatcher, fieldForceWatcher,
  manualAdjustmentsWatcher, manualCommissionRecordsWatcher, exceptionsWatcher,
  clientNotesWatcher, agencyRequirementWatcher, deductionInquiryWatcher,
  fieldForcePaymentsModuleWatcher, fieldForceMatRelBalProdWatcher, relatioshipWatcher,
  fieldForceNoteDedTaskAdmWatcher, licenceWatcher, fieldForceLetterWatcher } from '../app/modules/agency';
import { activityWatcher, fpaMonitoringWatcher } from '../app/modules/activity';
import { CSWatcher, SCSWatcher, rateMasterWatcher, extRateModuleWatcher } from '../app/modules/compcal/redux';
import { reportWatcher, reportRepositoryWatcher } from '../app/modules/report/redux';
import { accountingInquiryWatcher } from '../app/modules/accounting/redux';
import moduleWatcher from '../app/modules/payments/redux/effects/paymentModuleWatcher';
import { codesAndHierarchyWatcher, advisorDetailsModuleWatcher } from '../app/modules/codesAndHierarchyExternal';

/**
 * All the moe saga needs to be configured here
 */
export default function* AppRootSaga() {
  yield all([ApplicationBaseWatcher(), adminWatcher(), adminGenEditWatcher(), agencyWatcher(), tempAdjustmentWatcher(),
    activityWatcher(), fpaMonitoringWatcher(), CSWatcher(), clientWatcher(), fieldForceWatcher(), fieldForceMatRelBalProdWatcher(),
    fieldForceNoteDedTaskAdmWatcher(), clientNotesWatcher(), sysParamWatcher(), transacDefWatcher(),
    incomeExpWatcher(), chequeSecurityWatcher(), configureLettersWatcher(), dailyProcessWatcher(), interfaceMapWatcher(),
    manualCommissionRecordsWatcher(), agencyRequirementWatcher(), reportWatcher(), manualAdjustmentsWatcher(),
    exceptionsWatcher(), SCSWatcher(), deductionInquiryWatcher(), fieldForcePaymentsModuleWatcher(),
    relatioshipWatcher(), rateMasterWatcher(), accountingInquiryWatcher(), interfaceSetupWatcher(),
    producerAuditTrailWatcher(), reportRepositoryWatcher(), notificationsWatcher(), DataTransferWatcher(),
    fieldForceStructureModuleWatcher(), accessOptWatcher(), licenceWatcher(), fieldForceLetterWatcher(), moduleWatcher(),
    manageSecurityGroupWatcher(), manageUserWatcher(), manageLevelWatcher(), fieldforceSecurityWatcher(),
    codesAndHierarchyWatcher(), advisorDetailsModuleWatcher(), extRateModuleWatcher()]);
}
