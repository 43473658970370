/* eslint-disable import/no-cycle */
/* eslint-disable no-debugger */
/**
 * @Description File consists all the common utility for the program
 * @FileName utility.js
 * @Author Arnab Sutar - sutaarn
 * @CreatedOn 11 January, 2021 04:21:20
 * @IssueID - 318, 305
 */

import { LogEventLevel } from 'structured-log';
import { useSelector } from 'react-redux';
import numeral from 'numeral';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import appHistory from './appHistory';
import { exportFileType, globalConstants, staticCommonLabelKeys } from '../moduleConstants';
import { getValueFromLocalStorage, STORAGE_KEY } from './localStorageService';
import { setLookUpsData } from '../redux/actions/commonActions';

const { dateFormatMMDDYYYY, dateFormatYYYYMMDD, dateFormatDDMMYYYHHMM } = globalConstants;

/**
  * Register the localization option for number formatting.
  * Currently only english and french has been configured
  */
numeral.register('locale', 'fr', {
  delimiters: {
    thousands: ' ',
    decimal: ',',
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't',
  },
  // ordinal: (number) => (number === 1 ? 'er' : 'ème'),
  currency: {
    symbol: 'C$',
  },
});

const channelHierarchy = [
  'COMPANY',
  'CHANNEL',
  'REGION',
  'DISTRIBUTOR',
  'ALTERNATE OFFICE',
  'LOCATION',
  'TEMP LEVEL 7',
  'CORPORATION',
  'AFFILIATE',
  'ADVISOR',
];

const caseNoTwo = 2;
const caseNoThree = 3;
const caseNoSeven = 7;
const caseNoFifteen = 15;
const caseNoThirtyOne = 31;
const caseNoSxityThreen = 63;
const caseNoOne = 1;
const notificationTime = 100000;
const pageSizeTen = '10';
/**
  * Get the log level numeric value for a log level string
  * @param {string} levelStr
  *
  * @returns numeric value of the log level
  */
export const getLogLevel = (levelStr) => {
  switch (levelStr) {
    case 'off':
      return LogEventLevel.off;
    case 'fatal':
      return LogEventLevel.fatal;
    case 'error':
      return LogEventLevel.error;
    case 'warning':
      return LogEventLevel.warning;
    case 'information':
      return LogEventLevel.information;
    case 'debug':
      return LogEventLevel.debug;
    case 'verbose':
      return LogEventLevel.verbose;
    default:
      return LogEventLevel.verbose;
  }
};

const serverObj = (e) => ({
  id: e.errorCode,
  msg: '',
  isError: true,
});

/**
  *
  * @param {LogEvent from structured-log} e
  * @returns enrich the log event by adding following properties :
  * - message
  * - levelValue
  * - level (updated to winston log level value.)
  */
export const enrichLogEvent = (e) => {
  if (!e) {
    return e;
  }
  e.message = e.messageTemplate ? e.messageTemplate.render(e.properties) : null;
  e.levelValue = e.level;
  switch (e.level) {
    case caseNoOne:
      e.level = 'fatal';
      break;
    case caseNoThree:
      e.level = 'error';
      break;
    case caseNoSeven:
      e.level = 'warning';
      break;
    case caseNoFifteen:
      e.level = 'information';
      break;
    case caseNoThirtyOne:
      e.level = 'debug';
      break;
    case caseNoSxityThreen:
      e.level = 'verbose';
      break;
    default:
      e.level = null;
      break;
  }
  return e;
};

/**
  * Application environment config detail.
  */
export const environment = () => (window.SERVER_DATA ? window.SERVER_DATA : {});

/**
  * set the locale for number format
  * @param {string} locale - en or fr
  */
export const setNumberLocale = (localeLang) => numeral.locale(localeLang);

/**
  * Format the number value for the locale
  * @param {number} val - value which needs to be formatted
  * @param {string} formatStr - format string for number formatting
  */
export const formatNumber = (val, formatStr = null) => numeral(val).format(formatStr || null);
export const navigate = (path) => {
  appHistory.push(path);
  return true;
};

/**
  *
  * @param {Array} e
  * @returns {Array} :
  * - sort in Alphabetic order
  */
export const alphabeticSort = (requestObj) => requestObj.sort((a, b) => {
  const textA = a.label.toUpperCase();
  const textB = b.label.toUpperCase();
  let flag = 0;
  if (textA < textB) {
    flag = -1;
  } else {
    flag = 1;
  }
  return flag;
});

/**
  *
  * @param {Array} e
  * @returns {Array} :
  * - sort in Channel Hierarchy
  */
export const sortChannelHierarchy = (requestObj) => requestObj.sort((a, b) => {
  const textA = a.label.toUpperCase();
  const textB = b.label.toUpperCase();
  let flag = 0;
  if (channelHierarchy.indexOf(textA) < channelHierarchy.indexOf(textB)) {
    flag = -1;
  } else {
    flag = 1;
  }
  return flag;
});

/**
  * validate method for frontend and backend rules
  * @param {string} fieldID
  * @param {object} formikData
  * @param {object} beErrors
  * @param {object} rules
  * @param {object} feRuleConfig
  * @param {boolean} isBorder
  * @param {Function} tran
  * @returns object
  */
export const getErrors = (fieldID, formikData, beErrors, rules, feRuleConfig, isBorder, tran) => {
  let controlErrors = [];
  let serverErrors = [];
  if (formikData.errors[fieldID] && formikData.touched[fieldID]) {
    controlErrors = [
      {
        id: formikData.errors[fieldID],
        msg: !isBorder && tran(feRuleConfig[formikData.errors[fieldID]]),
        isError: true,
      },
    ];
  }
  if (beErrors) {
    serverErrors = beErrors
      .filter((e) => rules?.indexOf(e.errorCode) > -1)
      .map((e) => (serverObj(e)));
  }
  return [...controlErrors, ...serverErrors];
};

export const getErrorsDup = (fieldID, formikData, beErrors, rules, feRuleConfig, isBorder, tran) => {
  let controlErrors = [];
  let serverErrors = [];
  if (formikData.errors[fieldID]) {
    controlErrors = [
      {
        id: formikData.errors[fieldID],
        msg: !isBorder && tran(feRuleConfig[formikData.errors[fieldID]]),
        isError: true,
      },
    ];
  }
  if (beErrors) {
    serverErrors = beErrors
      .filter((e) => rules?.indexOf(e.errorCode) > -1)
      .map((e) => (serverObj(e)));
  }
  return [...controlErrors, ...serverErrors];
};

/**
  * validate method for frontend and backend rules
  * @param {string} fieldID
  * @param {object} formikData
  * @param {object} beErrors
  * @param {object} rules
  * @param {object} feRuleConfig
  * @param {boolean} isBorder
  * @param {Function} tran
  * @returns object
  */
export const getAmountFieldErrors = (errParms, isBorder) => {
  const { formikData, fieldID, feRuleConfig, amountLength, tran, beErrors, rules } = errParms;
  let controlErrors = [];
  let serverErrors = [];
  if (formikData.errors[fieldID] && formikData.touched[fieldID]) {
    controlErrors = [
      {
        id: formikData.errors[fieldID],
        msg: !isBorder && tran(feRuleConfig[formikData.errors[fieldID]],
          { maxLength: amountLength[0], decimalLength: amountLength[1] }),
        isError: true,
      },
    ];
  }
  if (beErrors) {
    serverErrors = beErrors
      .filter((amtFieldErr) => rules.indexOf(amtFieldErr.errorCode) > -1)
      .map((amtFieldErr) => ({
        id: amtFieldErr.errorCode,
        msg: '',
        isError: true,
      }));
  }
  return [...controlErrors, ...serverErrors];
};

export const getErrorsObj = ({ fieldID, formik, serverErrors, rules,
  formRuleConfig, isBorder, tran }) => {
  let controlErrors = [];
  let serverErrs = [];
  if (formik.errors[fieldID] && formik.touched[fieldID]) {
    controlErrors = [
      {
        id: formik.errors[fieldID],
        msg: !isBorder && tran(formRuleConfig[formik.errors[fieldID]]),
        isError: true,
      },
    ];
  }
  if (serverErrs.length > 0) {
    serverErrs = serverErrors
      .filter((e) => rules.indexOf(e.errorCode) > -1)
      .map((e) => ({
        id: e.errorCode,
        isError: true,
        msg: '',
      }));
  }
  return [...controlErrors, ...serverErrs];
};

export const getAmountFieldErrorsObj = ({ fieldID, formik, serverErrors, rules,
  formRuleConfig, isBorder, tran }, amountFieldLength) => {
  let controlErrors = [];
  let serverErrs = [];
  if (formik.errors[fieldID] && formik.touched[fieldID]) {
    controlErrors = [
      {
        id: formik.errors[fieldID],
        msg: !isBorder && tran(formRuleConfig[formik.errors[fieldID]],
          { maxLength: amountFieldLength[0], decimalLength: amountFieldLength[1] }),
        isError: true,
      },
    ];
  }
  if (serverErrs.length > 0) {
    serverErrs = serverErrors
      .filter((amtFieldErrObj) => rules.indexOf(amtFieldErrObj.errorCode) > -1)
      .map((amtFieldErrObj) => ({
        id: amtFieldErrObj.errorCode,
        isError: true,
        msg: '',
      }));
  }
  return [...controlErrors, ...serverErrs];
};

/**
  * Control unexceptional Server Error
  * @param {object} error
  * @returns
  */
export const processError = (error) => {
  if (error && error.response && Array.isArray(error.response.data.errors)) {
    return error.response.data.errors.map((item) => {
      const responseItem = item;
      if (item.addlDetails) {
        const dynamic = item.addlDetails.split('|');
        const i18nObj = {};
        dynamic.forEach((value, index) => {
          i18nObj[`Key${index + 1}`] = value;
        });
        responseItem.alterObj = i18nObj;
      } else {
        responseItem.alterObj = null;
      }
      return responseItem;
    });
  }
  if (error && error.response && !Array.isArray(error.response.data.errors)) {
    return [
      {
        errorCode: 'UNKNOWN_ERROR',
        errorMessage: 'Operation could not be completed due to an unexpected error.',
        errorMessageId: 'static:GENERIC.NOTIFICATION',
        type: '',
        alterObj: null,
      },
    ];
  }
  return [
    {
      errorCode: 'UNKNOWN_ERROR',
      errorMessage: 'Something went wrong, please try again.',
      errorMessageId: 'COMMON_EXCEPTION_GENERIC',
      type: '',
      alterObj: null,
    },
  ];
};

/**
  * return column asyn or desc as per sorted Object or send null
  * @param {object} sortingObject
  * @param {string} columnID
  * @returns
  */
export const setColumnSettings = (sortingObject, columnID) => {
  if (sortingObject) {
    const foundIndex = sortingObject.findIndex((x) => x.key === columnID);
    if (foundIndex >= 0) {
      return sortingObject[foundIndex].asc === true ? 1 : -1;
    }
  }
  return null;
};

/**
  * configure column config list for filter component
  * @param {array} tableMetaData
  * @returns
  */
export const getColumns = (tableMetaData) => tableMetaData.columnConfig
  .map(({ id, header, aclKey, type, lookupKey, isSearchable }) => ({
    label: header,
    value: id,
    aclKey: aclKey || null,
    type: type || null,
    lookupKey: lookupKey || null,
    isSearchable: isSearchable || null,
  }))
  .sort((a, b) => {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  })
  .filter(({ label }) => label !== 'Actions');

/**
  * Format Column Order Item For UI readability
  * @param {array} selectedItem
  * @returns
  */
export const formatColumnOrderForUI = (selectedItem) => selectedItem
  .sort((a, b) => (a.order > b.order ? 1 : -1)).map((item) => item.key);

export const formatRoleList = (roleList) => roleList.map(({ sgrName }) => ({
  value: sgrName,
  label: sgrName,
}));

export const formatColumnOrderAsAPI = (selectedItem) => selectedItem.map((item, index) => ({
  key: item,
  oredr: index + 1,
}));

const formatRecordKey = ({ parameters, lockKey }) => {
  let recordKey = '';
  if (lockKey) {
    recordKey = parameters.filter(({ key }) => key === lockKey)[0].values.join();
  } else if (parameters) {
    parameters.map((item) => {
      recordKey = recordKey ? `${recordKey}:${item.values.join(':')}` : item.values.join(':');
      return item;
    });
  } else {
    recordKey = '';
  }
  return recordKey;
};

const formatFilterCriteriaParams = (customFilterCreteriaList) => customFilterCreteriaList
  .map(({ key, operator, type, values }) => {
    const valueArray = typeof values === 'string' ? values.split(',') : values;
    return {
      key,
      operator,
      type,
      values: valueArray === null ? [] : valueArray,
    };
  });

/**
  * Initial request payload for List request
  * @param {string} locale
  * @param {object} parameters
  * @param {object} sortOptions
  * @param {object} groupByParameters
  * @param {object} lookUpInformation
  * @returns
  */
const setInitialVal = (value, defaultVal) => value || defaultVal;
export const setParam = (value, defaultVal) => {
  let response = defaultVal;
  if ((value && Array.isArray(value))) {
    response = formatFilterCriteriaParams(value).filter((item) => {
      const oprChck1 = item.operator === 'OPT_IS_NULL' || item.operator === 'OPT_IS_NOT_NULL';
      const oprChck2 = item.operator === 'OPT_IS_EMPTY' || item.operator === 'OPT_IS_NOT_EMPTY';
      if (oprChck1 || oprChck2) {
        return item;
      }
      return item.values[0];
    });
  }
  return response;
};
export const initialReqObject = ({
  locale,
  currentPage,
  parameters,
  sortOptions,
  groupByParameters,
  isDistinct,
  lookUpInformation,
  pageSize,
  entityId,
  lockType,
  loggedUser,
  lockKey,
  listColumnOrder,
  isExtSrceen,
}) => ({
  entityId: `${entityId}`,
  active: true,
  export: 'NONE',
  page: setInitialVal(currentPage, 1),
  pageSize: setInitialVal(pageSize, pageSizeTen),
  locale: locale === 'en' ? 'E' : 'F',
  lookUpInformation: setInitialVal(lookUpInformation, []),
  columnOrders: listColumnOrder || [],
  parameters: setParam(parameters, []),
  groupByParameters: setInitialVal(groupByParameters, []),
  isDistinct: setInitialVal(isDistinct, false),
  sortOptions: setInitialVal(sortOptions, []),
  ...(isExtSrceen
    && { userId: isExtSrceen?.userId }),
  ...(lockType && { lock: {
    entityKey: `${entityId}`,
    recordKey: parameters ? formatRecordKey({ parameters, lockKey }) : '',
    source: 'ONLINE',
    lockId: 0,
    lockedBy: loggedUser?.UserId,
    lockStartTime: moment().format(),
    sessionId: '',
    action: lockType,
  } }),
  ...(lockKey && { lockKey }),
});

export const initReqObjBlankVal = ({
  locale,
  currentPage,
  parameters,
  sortOptions,
  groupByParameters,
  isDistinct,
  lookUpInformation,
  pageSize,
  entityId,
  lockType,
  loggedUser,
  lockKey,
  listColumnOrder,
}) => ({
  entityId: `${entityId}`,
  active: true,
  export: 'NONE',
  page: setInitialVal(currentPage, 1),
  pageSize: setInitialVal(pageSize, pageSizeTen),
  locale: locale === 'en' ? 'E' : 'F',
  lookUpInformation: setInitialVal(lookUpInformation, []),
  columnOrders: listColumnOrder || [],
  parameters: setInitialVal(parameters, []),
  groupByParameters: setInitialVal(groupByParameters, []),
  isDistinct: setInitialVal(isDistinct, false),
  sortOptions: setInitialVal(sortOptions, []),
  ...(lockType && { lock: {
    entityKey: `${entityId}`,
    recordKey: parameters ? formatRecordKey({ parameters, lockKey }) : '',
    source: 'ONLINE',
    lockId: 0,
    lockedBy: loggedUser?.UserId,
    lockStartTime: moment().format(),
    sessionId: '',
    action: lockType,
  } }),
  ...(lockKey && { lockKey }),
});

/**
  * Reset Upsert Object
  */
export const resetUpsertValue = {
  upsertRequest: null,
  upsertRequestError: null,
  upsertRequestedOn: null,
  upsertRequestProcessedOn: null,
  upsertData: null,
};

/**
  * Column Type Datatype
  */
export const COLUMN_TYPE = {
  TEXT: 'text',
  NUMBER: 'number',
  DATE: 'date',
  BOOLEAN: 'boolean',
  ACTION: 'action',
};

/**
  * Reset Delete Object
  */
export const resetDeletetValue = {
  deleteRequest: null,
  deleteRequestError: null,
  deleteRequestedOn: null,
  deleteRequestProcessedOn: null,
  deleteData: null,
};

/**
  * Replace occurence of characters in a strings
  * @param {string} strS
  * @param {string} replaceWith
  * @param {string} replace
  * @returns string
  */
export const replaceString = (str, replaceWith, replace) => str.toString().replace(replace, replaceWith);

/**
  * Add Zero After Integer
  * @param {number} num
  * @param {number} places
  * @returns string
  */
export const padZeroAfterInterger = (num, places) => {
  const zero = places - num.toString().length + 1;
  return num + Array(+(zero > 0 && zero)).join('0');
};

export const makePositiveAmount = (num, withSymbol) => {
  if (withSymbol) {
    const amtvalue = num * 1;
    const amountValue = amtvalue < 0 ? amtvalue * -1 : amtvalue;
    return amountValue.toString();
  }
  return num;
};

export const formatWithSymbolNumber = (num, formatedValue) => {
  const amtvalue = num * 1;
  if (amtvalue < 0) {
    return <font color="red">{`(${formatedValue})`}</font>;
  }
  return formatedValue;
};

/**
  * Currency Format
  * @param {number} value
  * @param {string} locale
  * @param {string} decimalPlaces
  * @returns string
  */
export const currencyFormat = (numb, locale, decimalPlaces, withSymbol) => {
  let lang;
  let currencyType;
  const num = numb !== undefined ? Number(numb) : 0;
  const currencySymbol = '$';
  const currencySymbolF = '€';
  let decimalSymbol;
  if (locale === 'en') {
    lang = 'en-US';
    currencyType = 'USD';
    decimalSymbol = '.';
  } else {
    lang = 'fr-FR';
    currencyType = 'EUR';
    decimalSymbol = ',';
  }
  const number = makePositiveAmount(num, withSymbol);

  const formatter = new Intl.NumberFormat(lang, {
    style: 'currency',
    currency: currencyType,
    minimumFractionDigits: decimalPlaces,
  });
  let data;
  const spChars = /[.]+/;
  if (spChars.test(number)) {
    const splitString = number.toString().split('.');
    data = formatter.format(splitString[0]);
    const numZeroPad = padZeroAfterInterger(splitString[1], decimalPlaces);
    const result = data.substring(0, data.lastIndexOf(decimalSymbol) + 1);
    data = result.concat('', numZeroPad);
    if (locale === 'fr') {
      data = data.concat(' ', currencySymbolF);
    }
  } else {
    data = formatter.format(number);
  }
  if (withSymbol) {
    const formatedValue = data.replace('€', '$');
    return formatWithSymbolNumber(num, formatedValue);
  }
  /* Symbol remover $, € */
  data = data.replace(currencySymbolF, '').trim();
  return data.replace(currencySymbol, '');
};

export const negetiveValueFormat = (num, locale, decimalPlaces) => {
  let formatedValue = null;
  const amtvalue = num * 1;
  const amountValue = amtvalue < 0 ? amtvalue * -1 : amtvalue;
  if (decimalPlaces > 0) {
    formatedValue = currencyFormat(amountValue, locale, decimalPlaces);
  } else {
    formatedValue = amountValue;
  }
  return formatWithSymbolNumber(num, formatedValue);
};

export const currencyExchange = (num, locale, decimalPlaces, withSymbol) => {
  let lang;
  let currencyType;
  const currencySymbol = '$';
  let decimalSymbol;
  if (locale === 'en') {
    lang = 'en-US';
    currencyType = 'USD';
    decimalSymbol = '.';
  } else {
    lang = 'fr-FR';
    currencyType = 'EUR';
    decimalSymbol = ',';
  }

  const formatter = new Intl.NumberFormat(lang, {
    style: 'currency',
    currency: currencyType,
    minimumFractionDigits: decimalPlaces,
  });
  let data;
  const spChars = /,+/;
  if (spChars.test(num)) {
    const splitString = num.replace(/\s/g, '').split(',');
    data = formatter.format(splitString[0]);
    const numZeroPad = padZeroAfterInterger(splitString[1], decimalPlaces);
    const result = data.substring(0, data.lastIndexOf(decimalSymbol) + 1);
    data = result.concat('', numZeroPad);
  } else {
    data = formatter.format(num);
  }
  const newData = data.slice(0, (data.length - 1));
  if (withSymbol) {
    return newData.replace('€', '$');
  }
  /* Symbol remover $, € */
  return newData.replace(currencySymbol, '');
};

/**
  * Currency Format
  * @param {number} value
  * @param {string} locale
  * @returns string
  */
export const currencyLabelFormat = (num, locale, decimalPlaces) => {
  if (locale === 'en') {
    return `$${currencyFormat(num, locale, decimalPlaces)}`;
  }
  return `${currencyFormat(num, locale, decimalPlaces)}$`;
};

/**
  * Currency Format To Decimal
  * @param {string} value
  * @param {string} locale
  * @returns string
  */
export const currencyFormatToDecimal = (value, locale) => {
  let splitedValue;
  if (locale === 'en' && value) {
    splitedValue = value.toString().split(',').join('');
  } else if (locale === 'fr' && value && value.toString().includes(',')) {
    splitedValue = value.split(',').join('.').split(/\s/g).join('');
  } else {
    splitedValue = value;
  }
  return splitedValue;
};

/**
  * Append zero before digit
  * @param {object} responseData
  * @returns
  */
export const padWithZeroes = (number, len) => {
  const zeroes = '0'.repeat(len);
  return zeroes.substring(number.toString().length, len) + number;
};

/**
  * render download file name
  * @param {object} responseData
  * @returns
  */
export const renderFileName = (responseData) => responseData.headers['content-disposition']
  .split(';')
  .find((n) => n.includes('filename='))
  .replace('filename=', '')
  .trim();

const checkSubMenu = ({ menuItem, userClaim }) => {
  let result = true;
  if (menuItem.aclKey && menuItem.aclKey.length > 0) {
    const aclObject = userClaim?.access.find(
      (i) => menuItem.aclKey.findIndex((ii) => ii === i.resource) > -1,
    );
    if (aclObject && (aclObject.permission !== 'ENABLED')) {
      result = false;
    }
    if (!aclObject) {
      result = false;
    }
  }
  return result;
};

/**
  * function to generate AC
  * @param {object} userClaim - RBAC data for user
  * @param {object} menuItem - Child menu Node
  */
export const IsACLEnabled = (userClaim, menuItem, loggedUser) => {
  let result = true;
  result = checkSubMenu({ menuItem, userClaim });
  if (result && (menuItem.subMenu && menuItem.subMenu.length > 0)) {
    // eslint-disable-next-line no-param-reassign
    menuItem.subMenu = menuItem.subMenu.filter((i) => IsACLEnabled(userClaim, i, loggedUser));
  }
  return result;
};
/**
  * function to generate AC
  * @param {object} userClaim - RBAC data for user
  * @param {object} menuItem - Child menu Node
  */
export const formatQuickMenus = (userClaim, menuItems, userType) => {
  const hasSubMenu = userClaim.subMenu && userClaim.subMenu.length > 0;
  if (userType === 'external') {
    if (hasSubMenu && userClaim.external) {
      userClaim.subMenu.map((i) => formatQuickMenus(i, menuItems, userType));
    }
    if (userClaim.external) {
      menuItems.push({ id: userClaim.id, label: userClaim.label });
    }
  }
  if (userType === 'internal') {
    if (hasSubMenu && !userClaim.external) {
      userClaim.subMenu.map((i) => formatQuickMenus(i, menuItems, userType));
    }
    if (!userClaim.external) {
      menuItems.push({ id: userClaim.id, label: userClaim.label });
    }
  }
  return menuItems;
};

export const formatMenuFunc = (uniqueItems, item) => {
  if (uniqueItems.length > 0) {
    if (uniqueItems.filter((idx) => idx.menuId === item.menuId).length > 0) {
      return true;
    }
    return uniqueItems.push(item);
  }
  return uniqueItems.push(item);
};
export const hasUserClaim = (aclkey) => {
  const userClaim = useSelector(({ ApplicationStateRBAC }) => ApplicationStateRBAC.rbacData);
  if (aclkey) {
    const aclObject = userClaim?.access.find((i) => i.resource === aclkey);
    if (aclObject) {
      return aclObject.permission === 'ENABLED';
    }
    return false;
  }
  return true;
};

export const checkForResourceAccess = (userClaim, resourceId) => {
  if (!resourceId) { // If it is the root level resource
    return true;
  }
  const aclObject = userClaim?.access.find((i) => i.resourceId === resourceId);
  if (aclObject) {
    return aclObject.parentResourceId === null || checkForResourceAccess(userClaim, aclObject.parentResourceId);
  }
  return false;
};

export const isHasUserClaim = (userClaim, aclkey) => {
  if (aclkey) {
    const aclObject = userClaim?.access.find((i) => i.resource === aclkey);
    if (aclObject) {
      return aclObject.permission === 'ENABLED' && checkForResourceAccess(userClaim, aclObject.parentResourceId);
    }
    return false;
  }
  return false;
};
export const makeNumber = () => {
  const targetLength = 4;
  const beginIndex = 3;
  const endIndex = 13;
  let seed = Date.now();
  const result = (seed).toString().padStart(targetLength, '0').slice(beginIndex, endIndex);
  seed = parseInt(result, 10);
  return seed;
};

export const getUniqueId = () => Date.now() + (makeNumber() * notificationTime).toFixed();

/**
  * reset request object for list when page page size change
  * @param {object, string}
  * @returns
  */
export const pageChngRequestObj = ({ reqObj, currentPageSize }) => {
  const reqObject = reqObj;
  reqObject.pageSize = currentPageSize;
  reqObject.page = 1;
  return reqObject;
};

const getLabelErr = (userPrefError, t, currentYear) => {
  if (userPrefError) {
    return t(staticCommonLabelKeys.LS_FOOTER_TEXT_ERROR, { currentYear });
  }
  return t('LS_FOOTER_TEXT', { currentYear });
};

export const FasatCopyRightMessage = () => {
  const [t] = useTranslation();
  const userPrefError = useSelector(({ ApplicationStateRBAC }) => ApplicationStateRBAC.userPrefError);
  const currentYear = new Date().getFullYear();
  return (
    <>{getLabelErr(userPrefError, t, currentYear)}</>
  );
};

export const setConSysLangParams = ({ storeSetState, locale }) => {
  let lang = null;
  if (storeSetState) {
    lang = locale === 'en' ? 'E/true' : 'F/true';
  } else {
    lang = locale === 'en' ? 'E/false' : 'F/false';
  }
  return lang;
};
export const temAdjApiFormat = (rowSelected, userId, isApprove) => {
  let selItem = '';
  rowSelected.map((item) => {
    selItem = `${selItem}${item.id},`;
    return item;
  });
  let responseUserId = { userId };
  if (isApprove) {
    responseUserId = { wadApproveUser: userId };
  }
  return { selectedIds: selItem.substring(0, selItem.length - 1), ...responseUserId };
};

/**
  * validate method for frontend and backend rules
  * @param {string} fieldID
  * @param {object} formikData
  * @param {object} beErrors
  * @param {object} rules
  * @param {object} feRuleConfig
  * @param {boolean} isBorder
  * @param {Function} tran
  * @returns object
  */
export const getErrorsPage = (fieldID, formikData, beErrors, rules, feRuleConfig, isBorder, tran) => getErrorsDup(
  fieldID, formikData, beErrors, rules, feRuleConfig, isBorder, tran,
);
export const getErrsDynFld = (fieldErr, formikData, beErrors, rules, feRuleConfig, isBorder, tran) => {
  let controlErrors = [];
  if (fieldErr) {
    controlErrors = [
      {
        id: formikData.errors[fieldErr],
        msg: !isBorder && tran(fieldErr),
        isError: true,
      },
    ];
  }
  return [...controlErrors];
};

const THREE = 3;
const TWO = 2;
const NINENINENINE = 999;
export const dateValidator = (value) => {
  if (value) {
    const dateArr = value.split('/');
    if (dateArr.length !== THREE || !dateArr[TWO] || dateArr[TWO] <= NINENINENINE
       || !moment(value, dateFormatMMDDYYYY).isValid()) {
      return false;
    }
    const dateIsBefore = moment(value, dateFormatMMDDYYYY)
      .isBefore(moment(globalConstants.minAllowedDate, dateFormatMMDDYYYY));
    if (dateIsBefore) {
      return false;
    }
  }
  return true;
};

export const dateFieldValidator = (value) => {
  if (value) {
    const dateArr = value.split('/');
    if (dateArr.length !== THREE || !dateArr[TWO] || dateArr[TWO] <= NINENINENINE
       || !moment(value, dateFormatMMDDYYYY).isValid()) {
      return false;
    }
  }
  return true;
};

export const isCheckBoxTrue = (val) => {
  if (val) {
    return 'checked';
  }
  return '';
};

export const padTime = (time) => (String(time).length === 1 ? `0${time}` : `${time}`);
export const getDateFormat = (value) => {
  if (value) {
    const dateFormat = getValueFromLocalStorage(STORAGE_KEY.DATE_FORMAT) || dateFormatMMDDYYYY;
    return moment(value).format(dateFormat);
  }
  return null;
};
export const getDateAPIFormat = (value) => {
  if (value) {
    return moment(value).format(dateFormatYYYYMMDD);
  }
  return null;
};

export const getCurrentDate = () => moment().format();

export const getCurrentDateFormat = (format) => moment().format(format);

export const getAddDaysToDate = (date, days) => moment(date).add(Number(days), 'days');

export const getSubtractDaysToDate = (date, days) => moment(date).subtract(Number(days), 'days');

export const getDateFormatYYMMDD = (date) => moment(date).format(dateFormatYYYYMMDD);

export const getDateFormatDDMMYYHHMM = (date) => moment(date).format(dateFormatDDMMYYYHHMM);

export const formatResponse = ({ action, responseData, listToDetail = false }) => {
  const { parameters, entityId, lockKey, lock } = action.data;
  let lockedBy = null;
  if (lock) {
    lockedBy = lock.lockedBy;
  }
  const comboLock = {
    entityKey: entityId,
    recordKey: formatRecordKey({ parameters, lockKey }),
    source: 'ONLINE',
    lockId: 0,
    lockedBy,
    lockStartTime: moment().format(),
    sessionId: '',
    action: globalConstants.lockEdit,
  };
  if (Array.isArray(responseData)) {
    return { data: responseData, comboLock, listToDetail };
  }
  return { ...responseData, comboLock, listToDetail };
};

/**
  * validate Numeric or Alpha Numeric
  * @param {boolean} condition
  * @param {string} value
  * @returns string
  */
export const formatNumAndAlphaNum = (condition, value) => {
  if (condition) {
    return value.replace(/[^0-9\\]+/g, '');
  }
  const hasNoSplChar = value.replace(/[^a-zA-Z0-9]/g, '');
  return hasNoSplChar.toUpperCase();
};

export const sumOfRecordCount = {
  columnType: 'text',
  dataField: 'sumRecordCount',
  disableFilter: false,
  disableSortBy: false,
  header: 'Sum of records count',
  id: 'sumRecordCount',
  isAction: false,
  visibility: 'sumRecordCount',
  width: 160,
  sortAscending: 'sumRecordCount',
  aclKey: 'aclKey',
  type: 'number',
  lookupKey: '',
};

export const exportErrorMsg = (errorCode, fileType = exportFileType.Excel) => {
  if (errorCode === globalConstants.errorCode402) {
    return [{
      errorCode: 'COMMON_EXCEPTION_EXPORT_MAX_LIMIT',
      errorMessage: 'The number of records has exceeded the maximum limit.',
      errorMessageId: 'COMMON_EXCEPTION_EXPORT_MAX_LIMIT',
      type: 'RULE',
    }];
  }
  // Added check for CSV msg for row limit
  // By default it will treat as excel file

  const exportErrorCode = fileType === exportFileType.CSV ? 'ERR_9972' : 'ERR_9942';
  return [{ errorCode: exportErrorCode, errorMessage: exportErrorCode, errorMessageId: exportErrorCode, type: 'RULE' }];
};

const getChildren = (data) => {
  if (data && data[0] && data[0].children && data[0].children.length > 0) {
    return data[0].children;
  }
  return [];
};
export const updateLookupDataCOMCHAFEG = (lookupDataObject) => {
  if (lookupDataObject && lookupDataObject.length > 0) {
    const IDItems = lookupDataObject.filter((lookUpItem) => lookUpItem.key === 'ID_LEVEL');
    if (IDItems && IDItems.length > 0) {
      const dataObject = IDItems[0].values;
      const channel = dataObject.filter((lookUpItem) => lookUpItem.lookUpDbType === 'CHA');
      const company = dataObject.filter((lookUpItem) => lookUpItem.lookUpDbType === 'COM');
      const region = dataObject.filter((lookUpItem) => lookUpItem.lookUpDbType === 'REG');

      lookupDataObject.push({ key: 'CHANNEL', values: getChildren(channel) });
      lookupDataObject.push({ key: 'COMPANY', values: getChildren(company) });
      lookupDataObject.push({ key: 'REGION', values: getChildren(region) });
      setLookUpsData(lookupDataObject);
    }
  }
};

export const notEmpty = (value) => !(value === undefined || value === null || value === '');

export const autoSelFirstRow = (selClass) => {
  const gridTable = document.getElementsByClassName(`${selClass}`)[0];
  if (gridTable) {
    const trElems = document.querySelectorAll(`.${selClass} tr`);
    trElems.forEach((row) => row.classList.remove('highlightedRow'));
    const element = document.getElementsByTagName('tbody')[0].rows[0];
    element.classList.add('highlightedRow');
  }
};

export const collapseExpandPnl = (className) => {
  const ariaExpanded = 'aria-expanded';
  if ((document.getElementById(className).getAttribute(ariaExpanded) === 'true')) {
    document.getElementById(className).click();
  }
};
export const expandPnl = (className) => {
  const ariaExpanded = 'aria-expanded';
  if ((document.getElementById(className).getAttribute(ariaExpanded) === 'false')) {
    document.getElementById(className).click();
  }
};
export const getCurrentLocaleFormListData = (listData) => ((listData.locale === 'en'
|| listData.locale === 'E') ? 'en' : 'fr');

export const setConLangParams = ({ locale }) => ((locale === 'en') ? 'E' : 'F');

// #region utility functions for parsing FPE errors in Payments and adjustments
export const formatJsonStringForFinProcErr = (s) => s.replace(/\s/g, '').replaceAll(',}', '}').replaceAll(',]', ']');

export const parseJSONWithError = (str, defaultValue) => {
  try {
    const json = JSON.parse(str);
    return json;
  } catch (e) {
    return defaultValue;
  }
};

export const getFormattedErrorForFinProc = (errCd, defaultErrMsg = 'static:GENERIC.NOTIFICATION') => {
  const errors = [];
  errCd.forEach((e) => {
    errors.push({ errorMessageId: e.PostStepErrorCode });
  });
  if (errors.length === 0) {
    errors.push({ errorMessageId: defaultErrMsg });
  }
  return errors;
};

export const downloadErrorMessage = (errorCode) => {
  if (errorCode === globalConstants.errorCode402) {
    return [{
      errorCode: 'FORM_LETTER_NOT_FOUND',
      errorMessage: 'Letter Not Found .',
      errorMessageId: 'FORM_LETTER_NOT_FOUND',
      type: 'RULE',
    }];
  }
  return [{
    errorCode: 'ERR_9942',
    errorMessage: 'ERR_9942',
    errorMessageId: 'ERR_9942',
    type: 'RULE',
  }];
};
// #endregion

export const addZerosToNumber = (num, places) => parseFloat(num).toFixed(places);

export const formatDecimalWithPrecision = (value, decimalPlaces = caseNoTwo) => {
  // Convert the value to a number
  const decimalValue = Number(value);

  // Ensure the value is a valid number
  if (Number.isNaN(decimalValue)) {
    return value;
  }

  // Convert number to string with the specified number of decimal places
  let strValue = decimalValue.toString();

  // Find the position of the decimal point
  const dotIndex = strValue.indexOf('.');

  // If there is no decimal point, add the appropriate number of zeros
  if (dotIndex === -1) {
    strValue += `.${'0'.repeat(decimalPlaces)}`;
  } else {
    // Ensure the string has the correct number of decimal places
    const decimalPart = strValue.substring(dotIndex + 1);
    if (decimalPart.length < decimalPlaces) {
      strValue += '0'.repeat(decimalPlaces - decimalPart.length);
    } else if (decimalPart.length > decimalPlaces) {
      strValue = strValue.substring(0, dotIndex + decimalPlaces + 1);
    } else {
      // do nothings
    }
  }
  return strValue;
};

export const formatToPercentage = (value, decimalPlaces = caseNoTwo) => {
  // Return the same value if it's null or undefined
  if (value === null || value === undefined) {
    return value;
  }
  const formattedString = formatDecimalWithPrecision(value, decimalPlaces);
  if (Number.isNaN(Number(formattedString))) {
    return formattedString;
  }
  // Append the percentage sign
  return `${formattedString}%`;
};

export const trimString = (value) => {
  if (value === null || value === undefined) {
    return '';
  }
  return value.toString().trim();
};

export const renderCurrencyWithSymbol = (value, locale, decimalPlaces = caseNoTwo) => {
  // Return the same value if it's null or undefined
  if (value === null || value === undefined) {
    return value;
  }
  let amount = value;
  let amountString = 0;
  let isNegative = 0;
  if (amount < 0) {
    isNegative = 1;
    amount *= -1;
  }

  const formattedString = currencyFormat(Number(amount)?.toFixed(decimalPlaces), locale);
  if (locale === 'en') {
    amountString = `$${formattedString}`;
  } else {
    amountString = `${formattedString}$`;
  }
  if (isNegative) {
    amountString = `(${amountString})`;
    return <font color="red">{`${amountString}`}</font>;
  }

  return <>{amountString}</>;
};
