/**
 * @Description This file contains all the API configuration for global module
 * @FileName apiGlobalConfig.js
 * @Author Abhisek Kundu - kundabh
 * @CreatedOn 07 May, 2021 10:36:00
 * @IssueID - 459
 */

import { environment } from '../../util';

const envData = environment();
/**
 * Module specific API endpoint configuration.
 */
const apiGlobalConfig = {
  GLOBAL_LOOKUPS_DATA: {
    endpoint: `${envData.apiHost}/api/lookups/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  OPERATOR_LIST: {
    endpoint: `${envData.apiHost}/api/searchfilters/operators`,
    type: 'GET',
    authorizationRequired: false,
    showLoading: true,
  },
  FILTER_LIST: {
    endpoint: `${envData.apiHost}/api/searchfilters/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  INSERT_FILTER: {
    endpoint: `${envData.apiHost}/api/searchfilters`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  UPDATE_FILTER: {
    endpoint: `${envData.apiHost}/api/searchfilters`,
    type: 'PUT',
    authorizationRequired: false,
    showLoading: true,
  },
  GET_SUB_MODULE_DETAIL: {
    endpoint: `${envData.apiHost}/api/entities/detail`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  SYSTEM_DEFAULT_FILTER: {
    endpoint: `${envData.apiHost}/api/searchfilters/defaults`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  SYSTEM_ROLE_LIST: {
    endpoint: `${envData.apiHost}/api/lookups/userroles`,
    type: 'GET',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FILTER_DELETE: {
    endpoint: `${envData.apiHost}/api/searchfilters`,
    type: 'DELETE',
    authorizationRequired: false,
    showLoading: true,
  },

  /* Authentication and Authorization */
  USER_DETAILS: {
    endpoint: '/bff/user',
    type: 'GET',
    authorizationRequired: false,
    showLoading: true,
  },
  USER_RBAC_DATA: {
    endpoint: `${envData.apiHost}/api/security/securedresources`,
    type: 'GET',
    authorizationRequired: false,
    showLoading: true,
  },
  USER_CONTACT_INFO: {
    endpoint: `${envData.apiHost}/api/security/userContactInfo`,
    type: 'GET',
    authorizationRequired: false,
    showLoading: true,
  },

  SYSTEM_CONFIG_DATA: {
    endpoint: `${envData.apiHost}/api/systemsetting/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },

  SYSOPTION_LOOKUPS_DATA: {
    endpoint: `${envData.apiHost}/api/lookups/systemoptions`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },

  USER_PREF_DATA: {
    endpoint: `${envData.apiHost}/api/userpreference/getlinks`,
    type: 'GET',
    authorizationRequired: false,
    showLoading: true,
  },
  GET_BATCH__PROCESSSING_STATE: {
    endpoint: `${envData.apiHost}/api/jobrequests/getbatrunstatus`,
    type: 'GET',
    authorizationRequired: false,
    showLoading: true,
  },
  RELEASE_LOCK: {
    endpoint: `${envData.apiHost}/api/security/releaselock`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  BATCH_EXPORT_DATA: {
    endpoint: `${envData.apiHost}/api/onlinejobrequest/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  DELETE_BATCH_EXPORT_JOB_DATA: {
    endpoint: `${envData.apiHost}/api/onlinejobrequest`,
    type: 'DELETE',
    authorizationRequired: false,
    showLoading: true,
  },
  DOWNLOAD_BATCH_EXPORT_JOB_DATA: {
    endpoint: `${envData.apiHost}/api/onlinejobrequest/downloadexcel`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
  GET_SYSTEM_RUN_DATE: {
    endpoint: `${envData.apiHost}/api/lookups/sysrundate`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  GET_JOB_REQUEST_STATUS_BY_JOB_ID: {
    endpoint: `${envData.apiHost}/api/onlinejobrequest/@@jobRequestId`,
    type: 'GET',
    authorizationRequired: false,
    showLoading: true,
  },
  GET_IP_INFO: {
    endpoint: 'https://ipinfo.io/json?token=5e4eae4b7ea318',
    type: 'GET',
    authorizationRequired: false,
    showLoading: true,
  },
  GET_ALL_QUICKS_LINKS: {
    endpoint: `${envData.apiHost}/api/userpreference/allquicklinks`,
    type: 'GET',
    authorizationRequired: false,
    showLoading: true,
  },
  GET_IAR_DATA_LINKS: {
    endpoint: `${envData.apiHost}/api/interactive/getDashboardAmount`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  GET_USER_QUICKS_LINKS: {
    endpoint: `${envData.apiHost}/api/userpreference/userquicklinks`,
    type: 'GET',
    authorizationRequired: false,
    showLoading: true,
  },
  SAVE_USER_QUICKS_LINKS: {
    endpoint: `${envData.apiHost}/api/userpreference`,
    type: 'PUT',
    authorizationRequired: false,
    showLoading: true,
  },
  GET_COMP_STATE_LIST_DASH: {
    endpoint: `${envData.apiHost}/api/compensationstatementdashboard/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  LEVEL_RBAC_DATA: {
    endpoint: `${envData.apiHost}/api/security/securedresources/level`,
    type: 'GET',
    authorizationRequired: false,
    showLoading: true,
  },
  SAVE_USER_LANGUAGE: {
    endpoint: `${envData.apiHost}/api/security/updateLang`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  CACHE_UPDATE: {
    endpoint: `${envData.apiHost}/api/security/updatecache`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
};

export default apiGlobalConfig;
