/* eslint-disable no-unused-vars */
/* eslint-disable no-unreachable */
/* eslint-disable no-debugger */
/* eslint-disable no-confusing-arrow */
/**
 * @Description Base saga/watcher file.
 * @FileName applicationBaseWatcher.js
 * @Author Arnab Sutar - sutaarn
 * @CreatedOn 22 January, 2021 08:51:22
 * @IssueID - 308
 */
import { takeEvery, put, all, call } from 'redux-saga/effects';
import FileSaver from 'file-saver';
import moment from 'moment';
import { getAction } from '../../../../stateManagement/reduxUtil';
import invokeApi from '../../apiManagement/invokeApi';
import { formatColumnOrderForUI, processError } from '../../util';
import {
  commonTypes,
  applicationLoadedSuccessfully,
  processingStarted,
  processingCompleted,
  setLookUpsData,
  setFilterSettings,
  setOperatorList,
  setFilterList,
  resetUpsertValue,
  setRoleList,
  getCustomFilterList,
  resetDeleteValue,
  setFilterListCount,
  setUserData,
  setRBACData,
  setUserPrefData,
  setUserPrefError,
  setNotLogin,
  setBatchProcessingState,
  setSysOptData,
  removeLockCombo,
  setBatchExportData,
  resetBatchValue,
  setScreenTime,
  setLevelRBACData,
  setUserLang,
  setUserContactInfo,
} from '../actions/commonActions';
import moduleAPI from '../config/apiGlobalConfig';
import { formatMenuFunc, formatQuickMenus, getUniqueId, renderFileName } from '../../util/utility';
import { addToLocalStorage, setUserSession, STORAGE_KEY } from '../../util/localStorageService';
import { globalConstants } from '../../moduleConstants';
import getMenuItems from '../../../../config/menuConfig';

const thirtySix = 36;

/**
  * Sample function to have an idea how saga works
  */
export function* performAppLoadedSideEffectDemo() {
  yield put(applicationLoadedSuccessfully());
}

/**
  * This generator function will invoke the api
  * @param {object} action - action which will request for LookUps Data
  */
export function* getLookUpsData(action) {
  let resetServerValue = null;
  yield all([put(processingStarted()),
    put(setUserPrefError(resetServerValue))]);
  try {
    const responseData = yield call(invokeApi, moduleAPI.GLOBAL_LOOKUPS_DATA, action.data);
    yield all([put(setLookUpsData(responseData)), put(processingCompleted())]);
  } catch (err) {
    // put request failed action
    resetServerValue = { serverRequestError: processError(err) };
    yield all([put(setUserPrefError(resetServerValue)),
      put(setLookUpsData(null)), put(processingCompleted())]);
  }
}

export function* getSysOptLookUpsData(action) {
  let resetServerValue = null;
  yield all([put(processingStarted()),
    put(setUserPrefError(resetServerValue))]);
  try {
    const responseData = yield call(invokeApi, moduleAPI.SYSOPTION_LOOKUPS_DATA, action.data);
    yield all([put(setSysOptData(responseData)), put(processingCompleted())]);
  } catch (err) {
    // put request failed action
    resetServerValue = { serverRequestError: processError(err) };
    yield all([put(setUserPrefError(resetServerValue)),
      put(setSysOptData(null)), put(processingCompleted())]);
  }
}

export function* getOperatotList() {
  let resetServerValue = null;
  yield all([put(processingStarted()),
    put(setUserPrefError(resetServerValue))]);
  try {
    const responseData = yield call(invokeApi, moduleAPI.OPERATOR_LIST);
    yield all([put(setOperatorList(responseData)), put(processingCompleted())]);
  } catch (err) {
    // put request failed action
    resetServerValue = { serverRequestError: processError(err) };
    yield all([put(setUserPrefError(resetServerValue)), setOperatorList(null), put(processingCompleted())]);
  }
}

export function* getFilterList(action) {
  let resetUpsertVal = null;
  yield all([put(processingStarted())]);
  try {
    const responseData = yield call(invokeApi, moduleAPI.FILTER_LIST, action.data.reqObjFilter);
    resetUpsertVal = {
      upsertRequest: null,
      upsertRequestError: null,
      upsertRequestedOn: null,
      upsertRequestProcessedOn: new Date(),
      upsertData: responseData[0],
    };
    yield all([
      put(resetUpsertValue(resetUpsertVal)),
      put(!action.data.listCount ? setFilterList(responseData) : setFilterListCount(responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    resetUpsertVal = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: null,
    };
    yield all([put(!action.data.listCount ? setFilterList(null) : setFilterListCount(null)),
      put(resetUpsertValue(resetUpsertVal)), put(processingCompleted())]);
  }
}

export function* getDefaultFilterData(action) {
  let resetServerValue = null;
  yield all([put(processingStarted()),
    put(setUserPrefError(resetServerValue))]);
  try {
    const responseData = yield call(invokeApi, moduleAPI.SYSTEM_DEFAULT_FILTER, action.data, null);
    if (responseData && responseData.length > 0) {
      const configureCOlumnOrder = responseData[0].detail.columnOrders
        .sort((a, b) => (a.order > b.order ? 1 : -1))
        .map((item) => item.key);
      const filterDetails = {
        parameters: null,
        sortOptions: null,
        columnOrder: configureCOlumnOrder,
      };
      yield all([put(setFilterSettings(filterDetails)), put(processingCompleted())]);
    }
  } catch (err) {
    // put request failed action
    const filterDetails = {
      parameters: null,
      sortOptions: null,
      columnOrder: null,
    };
    resetServerValue = { serverRequestError: processError(err) };
    yield all([put(setUserPrefError(resetServerValue)),
      put(setFilterSettings(filterDetails)), put(processingCompleted())]);
  }
}
const getSelectedItemExsists = (selectedItem) => {
  if (selectedItem && selectedItem.detail && selectedItem.detail.columnOrders) {
    return true;
  }
  return false;
};

const getSelectedCheckUserNotDefault = (action, selectedItem) => {
  if (!action.data.preUserDefaultFilter
     && selectedItem.isDefault
     && !selectedItem.isSystemFilter) {
    return true;
  }
  return false;
};

const getSelectedCheckForDefaultUser = (action, selectedItem) => {
  if (action.data.preUserDefaultFilter
     && !selectedItem.isDefault
     && !selectedItem.isSystemFilter) {
    return true;
  }
  return false;
};

const getUpsertFilterSettings = ({ selectedCheckUserNotDefault,
  selectedItem, selectedCheckDefaultUser, action }) => {
  if (selectedCheckUserNotDefault) {
    return selectedItem;
  }
  if (selectedCheckDefaultUser) {
    if ((action.data.preUserDefaultFilter
       === selectedItem.name)) {
      return null;
    }
  } else if ((action.data.preUserDefaultFilter
     && selectedItem.isDefaul && !selectedItem.isSystemFilter)) {
    return selectedItem;
  } else {
    return null;
  }
  return null;
};

const validateSelectedItem = (selectedItem) => (selectedItem && selectedItem.detail
 && selectedItem.detail.columnOrders);

const condition1 = (action, selectedItem) => (
  !action.data.preUserDefaultFilter && (selectedItem.isDefault && !selectedItem.isSystemFilter)
);

const condition2 = (action, selectedItem) => (
  action.data.preUserDefaultFilter && !selectedItem.isDefault && !selectedItem.isSystemFilter
);

const condition3 = (action, selectedItem) => (
  action.data.preUserDefaultFilter && (selectedItem.isDefault && !selectedItem.isSystemFilter)
);

const getSelectedUpsertFilter = ({ selectedItem, action }) => {
  if (validateSelectedItem(selectedItem)) {
    if (condition1(action, selectedItem)) {
      return selectedItem;
    }
    if ((condition2(action, selectedItem))
     && (action.data.preUserDefaultFilter === selectedItem.name)) {
      return null;
    }
    if (condition3(action, selectedItem)) {
      return selectedItem;
    }
  }
  return undefined;
};
export function* upsertFilter(action) {
  yield all([put(processingStarted())]);
  let resetUpsertVal = null;
  let responseData = null;
  try {
    if (action.data.formatObject.filter.id) {
      responseData = yield call(invokeApi, moduleAPI.UPDATE_FILTER, action.data.formatObject);
    } else {
      responseData = yield call(invokeApi, moduleAPI.INSERT_FILTER, action.data.formatObject);
    }
    if (responseData) {
      resetUpsertVal = {
        upsertRequest: null,
        upsertRequestError: null,
        upsertRequestedOn: null,
        upsertRequestProcessedOn: new Date(),
        upsertData: responseData[0],
      };
      const selectedItem = responseData[0];
      const selectedCheck = getSelectedItemExsists(selectedItem);
      const selectedCheckUserNotDefault = getSelectedCheckUserNotDefault(action, selectedItem);
      const selectedCheckDefaultUser = getSelectedCheckForDefaultUser(action, selectedItem);
      if (selectedCheck) {
        const configureColumnOrder = formatColumnOrderForUI(selectedItem.detail.columnOrders);
        selectedItem.detail.columnOrders = configureColumnOrder;
        getUpsertFilterSettings({ selectedCheckUserNotDefault,
          selectedItem,
          selectedCheckDefaultUser,
          action });
      }
      const userDefault = getSelectedUpsertFilter({ selectedItem, action });
      yield all([
        put(resetUpsertValue(resetUpsertVal)),
        put(getAction(action.data.setAction, { selectedItem, userDefault })),
        put(processingCompleted()),
      ]);
    }
  } catch (err) {
    // put request failed action
    resetUpsertVal = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: null,
    };
    yield all([put(resetUpsertValue(resetUpsertVal)), put(processingCompleted())]);
  }
}

/**
  * This generator function will invoke the api
  * @param {object} action - action which will update the filter Used On date
  */
export function* updateUsedOnFilter(action) {
  let resetServerValue = null;
  yield all([put(processingStarted()),
    put(setUserPrefError(resetServerValue))]);
  let responseData = null;
  try {
    responseData = yield call(invokeApi, moduleAPI.UPDATE_FILTER, action.data.formatObject);

    if (responseData) {
      yield put(processingCompleted());
    }
  } catch (err) {
    // put request failed action
    resetServerValue = { serverRequestError: processError(err) };
    yield all([put(setUserPrefError(resetServerValue)), put(processingCompleted())]);
  }
}

/**
  * This generator function will invoke the api
  * @param {object} action - action which will request for Sub Module Detail
  */
export function* getSubModuleDetails(action) {
  yield all([put(processingStarted())]);
  try {
    const responseData = yield call(
      invokeApi,
      moduleAPI.GET_SUB_MODULE_DETAIL,
      action.data.entityId,
    );
    yield all([put(getAction(action.data.setAction, responseData)), put(processingCompleted())]);
  } catch (err) {
    // put request failed action
    yield all([put(getAction(action.data.setAction, null)), put(processingCompleted())]);
  }
}

export const checkClmnOrd = (systemDefault) => systemDefault
 && systemDefault.detail && systemDefault.detail.columnOrders;

/**
  * This generator function will invoke the api
  * @param {object} action - action which will request for Sub Module Detail
  */
export function* getDefaultFilterSetting(action) {
  let resetServerValue = null;
  yield all([put(processingStarted()),
    put(setUserPrefError(resetServerValue))]);
  try {
    let responseData = [];
    if (action.data.entityId === globalConstants.number99) {
      responseData = [];
    } else {
      responseData = yield call(
        invokeApi,
        moduleAPI.SYSTEM_DEFAULT_FILTER,
        action.data.entityId,
      );
    }
    let selectedItem = null;
    const systemDefault = responseData.find((item) => item.isSystemFilter && item.isDefault);
    const userDefault = responseData.find((item) => !item.isSystemFilter && item.isDefault);
    if (checkClmnOrd(systemDefault)) {
      const configureColumnOrder = formatColumnOrderForUI(systemDefault.detail.columnOrders);
      systemDefault.detail.columnOrders = configureColumnOrder;
    }
    if (action.data.entityId?.entityId === thirtySix && systemDefault.detail && systemDefault.detail.parameters) {
      const { parameters } = systemDefault.detail;
      parameters.forEach((p) => {
        const p1 = p;
        if (p.key === 'aaiGenId') {
          p1.values = [action.data.loggedInUser];
        }
      });
    }
    if (userDefault && userDefault.detail && userDefault.detail.columnOrders) {
      const configureCOlumnOrder = formatColumnOrderForUI(userDefault.detail.columnOrders);
      userDefault.detail.columnOrders = configureCOlumnOrder;
    }
    if (userDefault) {
      selectedItem = userDefault;
    } else {
      selectedItem = systemDefault;
    }
    yield all([
      put(getAction(action.data.setAction, { selectedItem, systemDefault, userDefault })),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    const filterDetails = null;
    resetServerValue = { serverRequestError: processError(err) };
    yield all([put(setUserPrefError(resetServerValue)),
      put(getAction(action.data.setAction, filterDetails)), put(processingCompleted())]);
  }
}

export function* getRoleList() {
  let resetServerValue = null;
  yield all([put(processingStarted()),
    put(setUserPrefError(resetServerValue))]);
  try {
    const responseData = yield call(invokeApi, moduleAPI.SYSTEM_ROLE_LIST);
    yield all([put(setRoleList(responseData)), put(processingCompleted())]);
  } catch (err) {
    // put request failed action
    resetServerValue = { serverRequestError: processError(err) };
    yield all([put(setUserPrefError(resetServerValue)), put(processingCompleted())]);
  }
}

/**
  * This generator function will invoke the api
  * @param {object} action - action which will delete existing Filter
  */
export function* deleteFilter(action) {
  yield all([put(processingStarted())]);
  let resetDeleteData = null;
  try {
    const responseData = yield call(
      invokeApi,
      moduleAPI.ADMINISTRATION_FILTER_DELETE,
      action.data.formatObject,
    );
    if (responseData) {
      resetDeleteData = {
        deleteRequest: null,
        deleteRequestError: null,
        deleteRequestedOn: null,
        deleteRequestProcessedOn: new Date(),
        deleteData: responseData,
      };
    }
    yield all([
      put(resetDeleteValue(resetDeleteData)),
      put(getCustomFilterList(action.data.reqObject)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    resetDeleteData = {
      deleteRequest: null,
      deleteRequestError: processError(err),
      deleteRequestedOn: new Date(),
      deleteRequestProcessedOn: null,
      deleteData: null,
    };
    yield all([put(resetDeleteValue(resetDeleteData)), put(processingCompleted())]);
  }
}

const checkResponseData = (responseData) => (responseData && responseData.length > 0);
const getUri = (sub) => (sub && sub !== '' ? `/bff/logout?sub=${sub}` : '/bff/logout');

const setLocalStorage = () => {
  localStorage.setItem('logInTime', localStorage.getItem('logInTime') === (null || undefined || '')
    ? moment().format('YYYY-MM-DD hh:mm a') : localStorage.getItem('logInTime'));
};
/**
  * This function will get all user data
  * */
export function* getUserData() {
  yield put(processingStarted());
  try {
    const responseData = yield call(invokeApi, moduleAPI.USER_DETAILS);
    if (checkResponseData(responseData)) {
      const isBatchProcessing = yield call(invokeApi, moduleAPI.GET_BATCH__PROCESSSING_STATE);
      if (isBatchProcessing) {
        yield put(setBatchProcessingState(1));
      }
      const hostEnv = responseData.find((data) => data.Type === 'isexternal')?.Value;
      if (hostEnv === 'external') {
        const sub = responseData.find((data) => data.Type === 'sub')?.Value;
        const uri = getUri(sub);
        const dataResId = responseData.find((data) => data.Type === 'apid');
        const loggedUsr = {
          UserId: dataResId.Value.split('@')[0],
          UserName: responseData.find((data) => data.Type === 'given_name').Value,
          LogOutUrl: uri,
          SessionId: getUniqueId(),
          CustomToken: responseData.find((data) => data.Type === 'sessionid').Value,
          UserType: 'external',
        };
        setUserSession(loggedUsr.SessionId);
        yield all([
          localStorage.setItem('logInTime', localStorage.getItem('logInTime') === (null || undefined || '')
            ? moment().format('YYYY-MM-DD hh:mm a') : localStorage.getItem('logInTime')),
          put(setUserData(loggedUsr)),
          put(setNotLogin(false)),
        ]);
      } else {
        const sub = responseData.find((data) => data.Type === 'sub')?.Value;
        const uri = getUri(sub);
        const dataResId = responseData.find((data) => data.Type === 'preferred_username');
        const loggedUsr = {
          UserId: dataResId.Value.split('@')[0],
          UserName: responseData.find((data) => data.Type === 'name').Value,
          LogOutUrl: uri,
          SessionId: getUniqueId(),
          CustomToken: responseData.find((data) => data.Type === 'sessionid').Value,
          UserType: 'internal',
        };
        setUserSession(loggedUsr.SessionId);
        yield all([
          setLocalStorage(),
          put(setUserData(loggedUsr)),
          put(setNotLogin(false)),
        ]);
      }
      yield put(processingCompleted());
    } else {
      yield all([put(setUserData(null)), put(setNotLogin(true)), put(processingCompleted())]);
    }
  } catch (err) {
    yield all([put(setUserData(null)), put(setNotLogin(true)), put(processingCompleted())]);
  }
  const ipInfo = localStorage.getItem('ipInfo');
  if (!ipInfo) {
    localStorage.setItem('ipInfo', '');
  }
}

/**
  * This generator function will invoke the api
  * This function will get RBAC Data
  */
export function* getRBACData() {
  let resetServerValue = null;
  yield all([put(processingStarted()),
    put(setUserPrefError(resetServerValue))]);
  try {
    const responseData = yield call(invokeApi, moduleAPI.USER_RBAC_DATA);
    yield all([put(setRBACData(responseData)), put(processingCompleted())]);
  } catch (err) {
    // put request failed action
    resetServerValue = { serverRequestError: processError(err) };
    yield all([put(setUserPrefError(resetServerValue)), put(processingCompleted())]);
  }
}

export function* getUserContactInfo() {
  let resetServerValue = null;
  yield all([put(processingStarted()),
    put(setUserPrefError(resetServerValue))]);
  try {
    const responseData = yield call(invokeApi, moduleAPI.USER_CONTACT_INFO);
    yield all([put(setUserContactInfo(responseData)), put(processingCompleted())]);
  } catch (err) {
    // put request failed action
    resetServerValue = { serverRequestError: processError(err) };
    yield all([put(setUserPrefError(resetServerValue)), put(processingCompleted())]);
  }
}

const getDefaultSignOutLimit = ({ key, value }) => {
  if (key === globalConstants.FASAT_DEFAULT_DATEFORMAT) {
    addToLocalStorage(STORAGE_KEY.DATE_FORMAT, value.sysValue);
    globalConstants.dateFormatMMDDYYYY = value.sysValue;
  }
  if (key === globalConstants.FASAT_DEFAULT_SIGNOUT_LIMIT) {
    addToLocalStorage(STORAGE_KEY.DEFAULT_LOGOUT_TIME, value.sysValue);
  }
  if (key === globalConstants.WEB_BATCH_EXPIRY_DAYS) {
    addToLocalStorage(STORAGE_KEY.WEB_BATCH_EXPIRY_DAYS, value.sysValue);
  }
};
/**
  * This generator function will invoke the api
  * This function will get System Config Data
  */
export function* getSysConfig(action) {
  let resetServerValue = null;
  yield all([put(processingStarted()),
    put(setUserPrefError(resetServerValue))]);
  try {
    const responseData = yield call(invokeApi, moduleAPI.SYSTEM_CONFIG_DATA, action.data);
    responseData.map(({ key, value }) => {
      getDefaultSignOutLimit({ key, value });
      if (key === globalConstants.DAILYPROCESS_BATCH_LIVE_UPDATE_INTERVAL_ONLINE) {
        addToLocalStorage(STORAGE_KEY.DAILYPROCESS_BATCH_LIVE_UPDATE_INTERVAL_ONLINE, value.sysValue);
      }
      if (key === globalConstants.DOWNLOAD_CENTRE_REFRESH_INTERVAL_ONLINE) {
        addToLocalStorage(STORAGE_KEY.DOWNLOAD_CENTRE_REFRESH_INTERVAL_ONLINE, value.sysValue);
      }
      if (key === globalConstants.FPA_PP_THRESHOLD_TIME_LIMIT) {
        addToLocalStorage(STORAGE_KEY.FPA_PP_THRESHOLD_TIME_LIMIT, value.sysValue);
      }
      if (key === globalConstants.FASAT_EXTERNAL_DEFAULT_SIGNOUT_LIMIT) {
        addToLocalStorage(STORAGE_KEY.FASAT_EXTERNAL_DEFAULT_SIGNOUT_LIMIT, value.sysValue);
      }
      if (key === globalConstants.FASAT_EXTERNAL_MAX_SIGNOUT_LIMIT) {
        addToLocalStorage(STORAGE_KEY.FASAT_EXTERNAL_MAX_SIGNOUT_LIMIT, value.sysValue);
      }
      if (key === globalConstants.FASAT_INTERNAL_MAX_SIGNOUT_LIMIT) {
        addToLocalStorage(STORAGE_KEY.FASAT_INTERNAL_MAX_SIGNOUT_LIMIT, value.sysValue);
      }
      if (key === globalConstants.FASAT_INTERNAL_CALLING_TIME) {
        addToLocalStorage(STORAGE_KEY.FASAT_INTERNAL_CALLING_TIME, value.sysValue);
      }
      return true;
    });
    yield all([
      put(setScreenTime(responseData?.filter((idx) => idx.key === 'FPA_THRESHOLD_TIME_LIMIT')[0]?.value?.sysValue)),
      put(processingCompleted())]);
  } catch (err) {
    // put request failed action
    resetServerValue = { serverRequestError: processError(err) };
    yield all([put(setUserPrefError(resetServerValue)), put(processingCompleted())]);
  }
}

/**
  * This generator function will invoke the api
  * This function will get user preference Data
  */
export function* getUserPrefData(action) {
  let resetServerValue = null;
  yield all([put(processingStarted()),
    put(setUserPrefError(resetServerValue))]);
  try {
    const responseData = yield call(invokeApi, moduleAPI.USER_PREF_DATA);
    yield all([put(setUserPrefData(responseData)), put(processingCompleted())]);
  } catch (err) {
    // put request failed action
    if (action.data !== globalConstants.GETLINKS) {
      resetServerValue = { serverRequestError: processError(err) };
    }
    yield all([put(setUserPrefData(null)), put(setUserPrefError(resetServerValue)), put(processingCompleted())]);
  }
}

/**
  * This generator function will invoke the api for
  * checking Batch process state
  */
export function* getBatchProcessingState() {
  let resetServerValue = null;
  yield all([put(processingStarted()),
    put(setUserPrefError(resetServerValue))]);
  try {
    const isBatchProcessing = yield call(invokeApi, moduleAPI.GET_BATCH__PROCESSSING_STATE);
    yield put(processingCompleted());
    if (isBatchProcessing) {
      yield all([put(setBatchProcessingState(1))]);
    }
  } catch (err) {
    // put request failed action
    resetServerValue = { serverRequestError: processError(err) };
    yield all([put(setUserPrefError(resetServerValue)), put(processingCompleted())]);
  }
}

/**
  * This generator function will invoke the api for
  * releasing lock for concurrency
  */
export function* releaseLock(action) {
  let resetServerValue = null;
  yield all([put(processingStarted())]);
  // put(setUserPrefError(resetServerValue)
  // )]);
  try {
    yield call(invokeApi, moduleAPI.RELEASE_LOCK, action.data);
    yield all([put(removeLockCombo(action.data)), put(processingCompleted())]);
  } catch (err) {
    // put request failed action
    resetServerValue = { serverRequestError: processError(err) };
    yield all([put(setUserPrefError(resetServerValue)), put(processingCompleted())]);
  }
}

/**
  * This generator function will invoke the api
  * This function will get user preference Data
  */
export function* getBatchExportData(action) {
  let resetServerValue = null;
  yield all([put(setUserPrefError(resetServerValue))]);
  try {
    const responseData = yield call(invokeApi, moduleAPI.BATCH_EXPORT_DATA, action.data);
    yield all([put(setBatchExportData(responseData))]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([put(setUserPrefError(resetServerValue)), setBatchExportData(null)]);
  }
}

/**
    * This generator function will invoke the api
    * @param {object} action - action which will delete existing job
    */
export function* deleteBatchRequestJobData(action) {
  let resetServerValue = {
    batchRequestError: null,
    batchRequestedOn: new Date(),
  };
  yield all([put(processingStarted()), put(resetBatchValue(resetServerValue))]);
  try {
    const responseData = yield call(
      invokeApi,
      moduleAPI.DELETE_BATCH_EXPORT_JOB_DATA,
      action.data.jobRequestId,
    );
    if (responseData) {
      yield put(getAction(commonTypes.GET_BATCH_EXPORT_DATA, action.data.reqObj));
    }
    yield all([put(processingCompleted())]);
  } catch (err) {
    resetServerValue = {
      batchRequestError: processError(err),
      batchRequestedOn: new Date(),
    };
    yield all([put(resetBatchValue(resetServerValue)), put(processingCompleted())]);
  }
}

/**
 * This generator function will invoke the api
 * @param {object} action - action which will request for Batch Export Listing
 */
export function* downloadBatchReq(action) {
  let resetServerValue = {
    batchRequestError: null,
    batchRequestedOn: new Date(),
  };
  yield put(resetBatchValue(resetServerValue));
  yield put(processingStarted());
  const originalEndPoint = moduleAPI.DOWNLOAD_BATCH_EXPORT_JOB_DATA.endpoint;
  try {
    const jobRequestId = `/${action.data.jobRequestId}`;
    const updatedApiUrl = moduleAPI.DOWNLOAD_BATCH_EXPORT_JOB_DATA.endpoint
         + jobRequestId;
    moduleAPI.DOWNLOAD_BATCH_EXPORT_JOB_DATA.endpoint = updatedApiUrl;
    const responseData = yield call(invokeApi,
      moduleAPI.DOWNLOAD_BATCH_EXPORT_JOB_DATA);
    moduleAPI.DOWNLOAD_BATCH_EXPORT_JOB_DATA.endpoint = originalEndPoint;
    const filename = renderFileName(responseData);
    const urlData = window.URL.createObjectURL(new Blob([responseData.data]));
    FileSaver(urlData, filename);
    resetServerValue = {
      batchRequestError: null,
      batchRequestedOn: new Date(),
    };
    yield put(resetBatchValue(resetServerValue));
    yield put(processingCompleted());
  } catch (err) {
    moduleAPI.DOWNLOAD_BATCH_EXPORT_JOB_DATA.endpoint = originalEndPoint;
    const errorObject = [{
      errorCode: 'COMMON_EXCEPTION_GENERIC',
      errorMessage: 'Something went wrong, please try again.',
      errorMessageId: 'COMMON_EXCEPTION_GENERIC',
      type: 'RULE',
    }];
    resetServerValue = {
      batchRequestError: errorObject,
      batchRequestedOn: new Date(),
    };
    yield all([put(resetBatchValue(resetServerValue)), put(processingCompleted())]);
  }
}

/**
    * This generator function will invoke the api
    * @param {object} action - action which will get existing system date
    */
export function* getSysRunDate(action) {
  yield all([
    put(processingStarted()),
  ]);
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.GET_SYSTEM_RUN_DATE,
      action.data);
    yield all([
      put(getAction(commonTypes.SET_SYS_RUN_DATE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    yield all([
      put(getAction(commonTypes.RESET_SYS_RUN_DATE)),
      put(processingCompleted())]);
  }
}

/**
    * This generator function will invoke the api
    * @param {object} action - action which will get existing system date
    */
export function* getJobRequestStatusByJobId(action) {
  yield all([put(processingStarted())]);
  try {
    const responseData = yield call(
      invokeApi,
      moduleAPI.GET_JOB_REQUEST_STATUS_BY_JOB_ID,
      {},
      { jobRequestId: action.data.jobRequestId },
    );

    if (responseData) {
      yield put(getAction(action.data.responseAction, responseData));
    }
    yield put(processingCompleted());
  } catch (err) {
    const error = processError(err);
    yield all([
      put(getAction(action.data.errorAction, error)),
      put(processingCompleted()),
    ]);
  }
}

/**
 * This generator function to fetch all available quicks links respect to security role
 * @param {object} action - N/A
 */
export function* getAllQuicksLinks(action) {
  yield put(processingStarted());
  const userType = action.data.type ? action.data.type : 'internal';
  try {
    const responseData = yield call(invokeApi, moduleAPI.GET_ALL_QUICKS_LINKS);
    const responseObj = [];
    const menuItems = [];
    const uniqueItems = [];
    responseData.forEach((item) => formatMenuFunc(uniqueItems, item));
    getMenuItems().forEach((item) => formatQuickMenus(item, menuItems, userType));
    const rbacMenus = uniqueItems.filter((mainItem) => menuItems.find((item) => {
      const itemObj = mainItem;
      if (mainItem.menuId === item.id) {
        itemObj.label = item.label;
        return itemObj;
      }
      return false;
    }));
    rbacMenus.forEach((idx) => responseObj.push({ value: idx.entityId.toString(),
      batch_Online_Concurrency_Flag: idx.batch_Online_Concurrency_Flag,
      label: idx.label,
      menuId: idx.menuId,
      shortName: idx.shortName }));
    yield all([
      put(getAction(commonTypes.SETALLQUICKSLINKS, responseObj)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    const resetServerValue = { serverRequestError: processError(err) };
    yield all([put(setUserPrefError(resetServerValue)), put(processingCompleted())]);
  }
}
/**
 * This generator function to fetch InteractiveActivitydata dashboard links respect to security role
 * @param {object} action - N/A
 */
export function* getIARdata(action) {
  yield put(processingStarted());
  try {
    const responseData = yield call(invokeApi, moduleAPI.GET_IAR_DATA_LINKS, action.data);
    yield all([
      put(getAction(commonTypes.SETIARDATA, responseData.data)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    const resetServerValue = { serverRequestError: processError(err) };
    yield all([put(setUserPrefError(resetServerValue)), put(processingCompleted())]);
  }
}
/**
 * This generator function to fetch all available quicks saved by user
 * @param {object} action - N/A
 */
export function* getUserQuicksLinks() {
  yield put(processingStarted());
  try {
    const responseData = yield call(invokeApi, moduleAPI.GET_USER_QUICKS_LINKS);
    const responseObj = [];
    responseData.map(({ entityId }) => responseObj.push(entityId.toString()));
    yield all([
      put(getAction(commonTypes.SETUSERQUICKSLINKS, responseObj)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    const resetServerValue = { serverRequestError: processError(err) };
    yield all([put(setUserPrefError(resetServerValue)), put(processingCompleted())]);
  }
}
/**
 * This generator function to fetch Comp Statement List for External Dashboard
 * @param {object} action - N/A
 */
export function* getCompStateListDash(action) {
  yield put(processingStarted());
  const { payload, setListData } = action.data;
  try {
    const responseData = yield call(invokeApi, moduleAPI.GET_COMP_STATE_LIST_DASH, payload);
    setListData(responseData);
    yield all([
      // put(getAction(commonTypes.SETUSERQUICKSLINKS, responseObj)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    const resetServerValue = { serverRequestError: processError(err) };
    yield all([put(setUserPrefError(resetServerValue)), put(processingCompleted())]);
  }
}
/**
 * This generator function to save all selected quicks selected by user
 * @param {object} action - N/A
 */
export function* saveUserQuicksLinks(action) {
  yield put(processingStarted());
  let resetUpsertVal = null;
  try {
    const responseData = yield call(invokeApi, moduleAPI.SAVE_USER_QUICKS_LINKS, action.data);
    resetUpsertVal = {
      upsertRequest: null,
      upsertRequestError: null,
      upsertRequestedOn: null,
      upsertRequestProcessedOn: new Date(),
      upsertData: responseData[0],
    };
    yield all([put(resetUpsertValue(resetUpsertVal)), put(processingCompleted())]);
  } catch (err) {
    resetUpsertVal = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: null,
    };
    yield all([put(resetUpsertValue(resetUpsertVal)), put(processingCompleted())]);
  }
}

/**
 * This generator function to save all selected quicks selected by user
 * @param {object} action - N/A
 */
export function* saveUserLanguage(action) {
  yield put(processingStarted());
  let resetUpsertVal = null;
  try {
    const responseData = yield call(invokeApi, moduleAPI.SAVE_USER_LANGUAGE, action.data);
    resetUpsertVal = {
      upsertRequest: null,
      upsertRequestError: null,
      upsertRequestedOn: null,
      upsertRequestProcessedOn: new Date(),
      upsertData: responseData,
    };
    yield all([put(resetUpsertValue(resetUpsertVal)), put(setUserLang(action.data.userLang)),
      put(processingCompleted())]);
  } catch (err) {
    resetUpsertVal = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: null,
    };
    yield all([put(resetUpsertValue(resetUpsertVal)), put(processingCompleted())]);
  }
}

/**
  * This generator function will invoke the api
  * This function will update cache
  */
export function* getCacheUpdate(action) {
  let resetServerValue = null;
  yield all([put(processingStarted()),
    put(setUserPrefError(resetServerValue))]);
  try {
    yield call(invokeApi, moduleAPI.CACHE_UPDATE, action.data);
    yield all([put(processingCompleted())]);
  } catch (err) {
    // put request failed action
    resetServerValue = { serverRequestError: processError(err) };
    yield all([put(setUserPrefError(resetServerValue)), put(processingCompleted())]);
  }
}
/**
  * This generator function will invoke the api
  * This function will get RBAC Data
  */
export function* getLevelRBACData() {
  let resetServerValue = null;
  yield all([put(processingStarted()),
    put(setUserPrefError(resetServerValue))]);
  try {
    const responseData = yield call(invokeApi, moduleAPI.LEVEL_RBAC_DATA);
    yield all([put(setLevelRBACData(responseData)), put(processingCompleted())]);
  } catch (err) {
    // put request failed action
    resetServerValue = { serverRequestError: processError(err) };
    yield all([put(setUserPrefError(resetServerValue)), put(processingCompleted())]);
  }
}
/**
  * Base application watcher.
  * If there is any watcher which would be used across
  * modules; those needs to be configured here
  */
function* applicationBaseWatcher() {
  yield takeEvery(commonTypes.APPLICATION_LOAD_REQ, performAppLoadedSideEffectDemo);
  yield takeEvery(commonTypes.LOOKUPS_DATA_REQ, getLookUpsData);
  yield takeEvery(commonTypes.SYSOPT_DATA_REQ, getSysOptLookUpsData);
  yield takeEvery(commonTypes.GET_DEFAULT_FILTER_DATA, getDefaultFilterData);
  yield takeEvery(commonTypes.GET_OPERATOR_LIST, getOperatotList);
  yield takeEvery(commonTypes.GET_FILTER_LIST, getFilterList);
  yield takeEvery(commonTypes.UPSERT_FILTER_REQUEST, upsertFilter);
  yield takeEvery(commonTypes.UPDATE_USED_ON_FILTER_REQUEST, updateUsedOnFilter);
  // fetch sub module details
  yield takeEvery(commonTypes.GET_SUB_MODULE_DETAILS, getSubModuleDetails);
  // fetch deafult filter Settings
  yield takeEvery(commonTypes.GET_DEFAULT_FILTER_SETTINGS, getDefaultFilterSetting);
  // fetch role list
  yield takeEvery(commonTypes.GET_ROLES_LIST, getRoleList);
  // delete filter request
  yield takeEvery(commonTypes.DELETE_FILTER_REQUEST, deleteFilter);
  // get user details
  yield takeEvery(commonTypes.GET_USER_DATA, getUserData);
  // yield takeEvery(commonTypes.GET_LOG_OUT, logoutUser);
  // get RBAC details
  yield takeEvery(commonTypes.GET_RBAC_DATA, getRBACData);
  yield takeEvery(commonTypes.GET_USER_CONTACT_INFO, getUserContactInfo);
  yield takeEvery(commonTypes.GET_SYS_CONFIG, getSysConfig);
  yield takeEvery(commonTypes.GET_USER_PREF_DATA, getUserPrefData);
  yield takeEvery(commonTypes.GET_BATCH_PROCESSING_STATUS, getBatchProcessingState);
  yield takeEvery(commonTypes.RELEASE_LOCK, releaseLock);
  yield takeEvery(commonTypes.GET_BATCH_EXPORT_DATA, getBatchExportData);
  yield takeEvery(commonTypes.DELETE_BATCH_EXPORT_JOB_REQUEST, deleteBatchRequestJobData);
  yield takeEvery(commonTypes.DOWNLOAD_BATCH_EXPORT_JOB_REQ, downloadBatchReq);
  // get system run date
  yield takeEvery(commonTypes.GET_SYSTEM_RUN_DATE, getSysRunDate);
  // get job request status by job id
  yield takeEvery(commonTypes.GET_JOB_REQUEST_STATUS_BY_JOB_ID, getJobRequestStatusByJobId);
  // get all quicks links related to user and security role
  yield takeEvery(commonTypes.GETALLQUICKSLINKS, getAllQuicksLinks);
  yield takeEvery(commonTypes.GETIARDATA, getIARdata);
  yield takeEvery(commonTypes.GETUSERQUICKSLINKS, getUserQuicksLinks);
  yield takeEvery(commonTypes.GETCOMPSTATELIST, getCompStateListDash);
  yield takeEvery(commonTypes.SAVEUSERQUICKSLINKS, saveUserQuicksLinks);
  yield takeEvery(commonTypes.SAVEUSERLANG, saveUserLanguage);
  // get level RBAC details
  yield takeEvery(commonTypes.GET_LEVEL_RBAC_DATA, getLevelRBACData);
  // CACHE UPDATE
  yield takeEvery(commonTypes.GET_CACHE_UPDATE, getCacheUpdate);
}

export default applicationBaseWatcher;
